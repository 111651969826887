.select-default-container .select-default__control {
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  background-color: var(--black-100) !important;
  border-radius: 0.75rem;
}
.dark .select-default-container .select-default__control {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.select-default-container .select-default__indicator {
  color: var(--black-700);
}
.select-default-container .select-default__option:active {
  background-color: var(--black-300);
}
.select-default-container .select-default__menu {
  border-radius: 0.375rem;
  box-shadow: 0 0 0 1px var(--black-200), 0 4px 16px rgba(11, 15, 25, 0.1);
}
.select-currency .select-default__single-value {
  font-weight: 600;
}

.dark .select-default-container .select-default__control {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark .select-default-container .select-default__menu {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1), 0 4px 16px rgba(11, 15, 25, 0.1);
}