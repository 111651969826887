@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

:root {
  --primary-color: #6366f1;
  --primary-hover: #4044ee;
  --white-color: #ffffff;
  --black-100: #f3f6ff;
  --black-200: #eff2fc;
  --black-300: #e2e5f1;
  --black-400: #d4d7e5;
  --black-500: #b4b7c9;
  --black-600: #9397ad;
  --black-700: #565973;
  --black-800: #33354d;
  --black-900: #0b0f19;
  --black-950: #020305;
  --card-primary: #242730;

  --drop-shadow-main: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.08));
}

body {
  font-family: "Inter", "Noto Sans Thai", sans-serif;
  color: var(--black-700);
  background-color: var(--white-color);
}

.dark body {
  color: var(--black-600);
  background-color: var(--black-950);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black-900);
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  color: var(--white-color);
}

.img-comparison-slider {
  --divider-width: 0.25rem;
  --divider-color: var(--black-900);
}

.swich-system {
  display: none !important;
}

.swich-dark,
.swich-light {
  transition: all 0.15s ease-in-out;
}

.swich-dark {
  opacity: 1;
}

.swich-light {
  opacity: 0;
}

.swich-group {
  transition: all 0.15s ease-in-out;
  transform: translateY(-40px);
}

.dark .swich-group {
  transform: translateY(0);
}

.dark .swich-dark {
  opacity: 0;
}

.dark .swich-light {
  opacity: 1;
}

.select-default-container .select-default__value-container {
  padding: 0;
}

.select-default-container .select-default__indicator {
  padding: 0 0 0 0.5rem;
}

.select-default-container .select-default__indicator-separator {
  display: none;
}

.select-default-container .select-default__control {
  border: none;
  min-height: 32px;
  background-color: transparent;
  box-shadow: none;
}

.select-default-container .select-default__input-container {
  margin: 0;
  padding: 0;
}

.dark .select-default-container .select-default__single-value {
  color: #ffffff;
}

.dark .select-default-container .select-default__menu {
  background-color: #020617;
}

.faq-accordion {
  --faq-heading-font-size: 1rem;
  --faq-heading-padding: 1rem;
  --faq-chevron-dark: url("data:image/svg+xml,%0A%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_493_7585)'%3E%3Cpath d='M8.5 19.9512L15.5 12.9512L8.5 5.95117' stroke='%232F2F37' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_493_7585'%3E%3Crect width='24' height='24' fill='white' transform='matrix(0 -1 1 0 0 24.9512)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  --faq-chevron-light: url("data:image/svg+xml,%0A%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_493_7585)'%3E%3Cpath d='M8.5 19.9512L15.5 12.9512L8.5 5.95117' stroke='%23FFFFFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_493_7585'%3E%3Crect width='24' height='24' fill='white' transform='matrix(0 -1 1 0 0 24.9512)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.faq-accordion .szh-accordion__item-btn {
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: var(--faq-heading-font-size);
  padding: var(--faq-heading-padding);
}

.faq-accordion .szh-accordion__item {
  border-top: 1px solid var(--black-300);
}

.dark .faq-accordion .szh-accordion__item {
  border-top: 1px solid var(--black-800);
}

.faq-accordion .szh-accordion__item:first-child {
  border-top: 0;
}

.faq-accordion .szh-accordion__item-content {
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.faq-accordion .szh-accordion__item-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-accordion .szh-accordion__item-btn::after {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: var(--faq-chevron-dark);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dark .faq-accordion .szh-accordion__item-btn::after {
  background-image: var(--faq-chevron-light);
}

.faq-accordion .szh-accordion__item--expanded .szh-accordion__item-btn::after {
  transform: rotate(90deg);
}

@media (min-width: 768px) {
  .faq-accordion {
    --faq-heading-font-size: 1.125rem;
    --faq-heading-padding: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .faq-accordion {
    --faq-heading-font-size: 1.25rem;
    --faq-heading-padding: 1.5rem;
  }
}

.mobile-menu {
  visibility: hidden;
  transition: all 0.1s ease-in-out;
  transform: translateX(100%);
}

.mobile-menu.show {
  visibility: visible;
  transform: translateX(0);
}

.main {
  --sidebar-width: 18rem;
  --main-bg: #f1f3f4;
  display: flex;
  overflow-x: hidden;
  background-color: var(--main-bg);
}

.dark .main {
  --main-bg: var(--black-950);
}

.sidebar {
  width: 100%;
  max-width: var(--sidebar-width);
}

.content {
  position: relative;
  height: fit-content;
  padding-left: var(--sidebar-width);
  width: 100%;
}


@media (min-width: 1536px) {
  .main {
    --sidebar-width: 20rem;
  }
}